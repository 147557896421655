import React from 'react'
import SwiperCore, { Pagination, Mousewheel, A11y, Autoplay, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import ReactPlayer from 'react-player'
import { GatsbyImage } from "gatsby-plugin-image";

// Import Swiper styles
// import 'swiper/swiper.scss'
// import 'swiper/components/effect-coverflow/effect-coverflow.scss'
// import 'swiper/components/pagination/pagination.scss'

SwiperCore.use([Pagination, A11y, Mousewheel, Autoplay, EffectCoverflow])

export default ({ items }) => {
  return (
    <Swiper
      effect="coverflow"
      spaceBetween={10}
      slidesPerView={1.5}
      coverflowEffect={{ slideShadows: false }}
      pagination={{ type: 'bullets', clickable: true }}
      centeredSlides
      mousewheel={{ forceToAxis: true }}
      autoplay={{ delay: 10000 }}
    >
      {items.map(item => {
        if (item.type === 'video') {
          return (
            <SwiperSlide key={item.title}>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  padding: `${item.ratio * 100}% 0 0 0`
                }}
              >
                <ReactPlayer
                  title={item.title}
                  url={item.url}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  className="bg-video"
                />
              </div>
            </SwiperSlide>
          )
        }
        return (
          <SwiperSlide key={item.title}>
            <GatsbyImage image={item.url} className="rounded my-2" alt={item.title} />
          </SwiperSlide>
        );
      })}
      <br /> <br />
    </Swiper>
  );
}
