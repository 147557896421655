import React from 'react'
import classNames from 'classnames'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export default ({ price, name, bg, model }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          DipPowderCabinet: file(relativePath: { eq: "furniture/dip_powder_cabinet.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
            }
          }
          GelPolishCabinet: file(relativePath: { eq: "furniture/gel_polish_cabinet.png" }) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
            }
          }
          GelPolishCabinetCloseView: file(
            relativePath: { eq: "furniture/gel_polish_cabinet_close_view.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
            }
          }
          DipPowderCabinetCloseView: file(
            relativePath: { eq: "furniture/cabinet_close_view.png" }
          ) {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 500, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={data => (
        <div className={classNames(['row py-2', bg])}>
          <div className="col-lg-6 py-4 d-flex justify-content-center align-items-center animated fadeInLeft flex-wrap">
            <GatsbyImage
              image={data[`${model}CloseView`].childImageSharp.gatsbyImageData}
              style={{
                width: '50%',
                maxWidth: '350px',
                height: 'auto'
              }}
              alt=""
            />
            <GatsbyImage
              image={data[`${model}`].childImageSharp.gatsbyImageData}
              style={{
                width: '50%',
                maxWidth: '350px',
                height: 'auto'
              }}
              alt=""
            />
          </div>

          <div className="col-lg-6 d-flex flex-column justify-content-center animated fadeInRight slow">
            <div className="mb-2 d-flex align-items-center flex-wrap">
              <div className="mt-4 animated fadeInRight slow">
                <h2 className="text-uppercase mb-0">{name}</h2>
                <div className="text-secondary">Digital Color May Vary</div>

                <h2 className="text-secondary mt-2">$ {price}</h2>
              </div>
            </div>
          </div>
        </div>
      )}
    />
  )
}
