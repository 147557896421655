import React from 'react'

import MediaSwiper from './MediaSwiper'

export default ({ price, name, items }) => {
  return (
    <div className="row">
      <div className="col-lg-6 py-4 d-flex justify-content-center align-items-center animated  fadeInLeft">
        <div className="w-100 bg-light rounded shadow-sm">
          <MediaSwiper items={items} />
        </div>
      </div>

      <div className="col-lg-6 d-flex flex-column justify-content-center animated fadeInRight slow">
        <div className="mb-2 d-flex align-items-center flex-wrap">
          <div className="animated fadeInRight slow">
            <h2 className="text-uppercase mb-0">{name}</h2>
            <div className="text-secondary">Digital Color May Vary</div>
            {/* <p className="my-0">Optional UV Hole at No Additional Cost</p> */}
            <p className="my-0">
              Optional Additional Cost: <strong>Air Vent, Granite Top or Glass Top</strong>
            </p>
            <h2 className="text-secondary my-0">$ {price}</h2>
          </div>
        </div>
      </div>
    </div>
  )
}
